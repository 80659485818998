.nav{
     @apply
     bg-fuchsia-700 
     w-full 
     h-[100px]
     py-6 
     px-12 
     flex 
     justify-between 
     items-center 
     relative
}

.home{
     @apply
     text-white 
     cursor-pointer
}
.cart{
     @apply
     text-white 
     cursor-pointer
}
.totalcart{
     @apply
     absolute 
     top-3 
     h-6 
     w-6  
     text-center 
     text-white 
     bg-red-600 
     rounded-full
}